import { ajax } from 'OK/utils/utils';

function getCount(q) {
    var el = document.querySelector(q),
        value = el ? el.innerHTML : null;

    value = parseInt(value, 10);
    if (isNaN(value)) {
        value = 0;
    }
    return value;
}

function activate(el) {
    ajax({
        url: '/dk',
        data: {
            'cmd': 'BehaviorLogger',
            'st.place': el.getAttribute('data-place'),
            'st.msgCount': getCount('#counter_ToolbarMessages .counterText'),
            'st.msgHasReply': !!document.querySelector('#counter_ToolbarMessages.notifications__reply')
        }
    });
}

export default { activate };

export { activate };
